// External jQuery
import $ from 'jquery';

$( document ).ready( function() {
	// eslint-disable-next-line no-console
	console.log( 'Everything is ready. ThemePlate!' );
} );

$( '#offcanvas-toggle' ).on( 'click', function( e ) {
	e.preventDefault();

	if ( $( 'body' ).hasClass( 'offcanvas-active' ) ) {
		$( 'body' ).removeClass( 'offcanvas-active' );
		setTimeout( function() {
			$( '#offcanvas' ).hide();
			$( '.rev_slider' ).revresume();
			$( window ).trigger( 'resize' );
		}, 500 );
		$( this ).attr( 'aria-expanded', 'false' );
	} else {
		$( '#offcanvas' ).show();
		$( 'body' ).addClass( 'offcanvas-active' );
		$( this ).attr( 'aria-expanded', 'true' );
		$( '.rev_slider' ).revpause();
	}
} );

$( document ).on( 'click', function( e ) {
	if ( $( 'body' ).hasClass( 'offcanvas-active' ) &&
	e.target.id !== 'offcanvas' && ! $( e.target ).parents( '#offcanvas' ).length &&
	e.target.id !== 'offcanvas-toggle' && ! $( e.target ).parents( '#offcanvas-toggle' ).length ) {
		$( 'body' ).removeClass( 'offcanvas-active' );
		setTimeout( function() {
			$( '#offcanvas' ).hide();
			$( '.rev_slider' ).revresume();
			$( window ).trigger( 'resize' );
		}, 500 );
	}
} );

function initSlick() {
	if ( typeof $.fn.slick === 'function' ) {
		$( '.testimonial-carousel' ).slick( {
			autoplay: true,
			arrows: false,
			speed: 4000,
			dots: true,
		} );

		window.clearInterval( checkSlick );
	}
}

const checkSlick = window.setInterval( initSlick, 200 );
